// Контейнер с хлебными крошками
@at-root #content-header {
  //margin-bottom: 1.5rem;
  .breadcrumb {
    padding: 0 0 7px;
    color:#484848;
    font-size:11px;
    font-weight:normal;
    text-align:left;
    border-bottom:1px solid #d1d9e0;
    margin-bottom: 0;
    line-height: 13px;
    display: block;
    a{
      color:#0055a2;
      text-decoration:none;
      padding:0 5px 0 2px;
      text-transform:none;
      vertical-align:middle;
      font-weight:normal;
      &:hover{text-decoration:underline;color:#000;}
      &:nth-child(2) {
        padding-left: 5px;
      }
    }
  }
  & > .title {
    & > h3 {
      font-family: $cp-font-family-special-header;
      color: var(--bs-body-color);
      font-size: 1.75rem;
    }
  }
}

#main {
  .messages.error {
    border: 1px solid red;
    padding-top: 15px;
    color: #f74545;
    margin-top: 20px;
  }
}

.node {
  margin-top: 1.5rem;
  overflow-wrap: anywhere; // Перенос супердлинных слов, например – checksum
  & > .editorial {
    display:inline-block;
    padding:0.375rem 0.75rem;
    font-size: 0.75rem;
    font-weight: 500;
    line-height: normal;
    vertical-align:baseline;
    &:empty{display:none;}
    margin-bottom:1rem!important;
    color:rgba(var(--bs-secondary-rgb),1)!important;
    background-color:rgba(var(--bs-primary-dark-rgb),1)!important;
  }
  .content {
    overflow: hidden; // Скрыть всё, что вылезает за пределы контентной области
    & > img, & > p > img, & > p > a > img, & > a > img { // Сужение изображения до размеров окна
      /* Если применить ко всем изображениям "& img", то это вызывает ошибку вёрстки тут https://cryptopro.ru/about/licenses */
      max-width: 100%;
      height: auto;
    }
    // Списки. Отступы для списка
    & ul.cp-list {
      margin-top: 0.7em;
      margin-left: 0.7em;
    }
    & ul.cp-list li {
      margin-bottom: 0.7em;
    }
    & > ul { // Украшение треугольниками списка
      padding-left: 0;
      li {
        list-style: none;
        background: url(data:image/gif;base64,R0lGODlhBAAGAIABAD2C5////yH5BAEAAAEALAAAAAAEAAYAAAIIBBKGqGnnUAEAOw==) no-repeat 0 6px;
        padding-left: 12px;
      }
    }
    // Дилеры
    @at-root img.imagefield-field_dealer_logo {
      max-width: 200px;
      height: auto;
    }
    // Решения партнёров
    @at-root table.dealer td:nth-child(1) > img {
      max-width: 200px;
      height: auto;
    }
    iframe { // Сужение до размеров окна
      max-width: 100%;
    }
    @media (max-width: $base-md) {
      & {
        //overflow: auto; // Возникает прокрутка в неожиданных местах
      }
    }
    & .news150 { // Изображение для новости
      float: left;
      margin: 0 20px 20px 0;
      max-width: 150px;
    }
    & .news280 { // Изображение для новости с расширением при открытии страницы отдельно на большом экране
      float: left;
      margin: 0 20px 20px 0;
      max-width: 150px;
      @at-root #main > .node > .content .news280 {
        max-width: 280px;
        @media (max-width: $base-sm) {
          & {
            max-width: 150px;
          }
        }
      }
    }
    // Ссылки под контентом. Например, "Печать"
    .links-indent {
      padding: 10px 0 0 0;
      overflow: hidden;
      ul.links {
        padding-left: 0;
        li {
          list-style: none;
          a {
            padding: 5px 10px;
            background: #0055a2;
            color: #fff;
            text-decoration: none;
            img.print-icon-margin {
              margin-right: 7px;
            }
          }
        }
      }
    }
  }
}

// Всем спискам, состоящим из вложенных .node
.views-row {
  border-bottom: 1px solid #e2e2e2;
  margin-bottom: 2rem;
  &:last-child {
    margin-bottom: 1rem;
  }
  h4.title {
    //font-size: 1.4rem;
    a {
      text-decoration:none;
      color:#0055a2;
      &:hover{
        text-decoration:none;
        color:#0f3262;
      }
    }
  }
  // Читать далее >>
  .read-more {
    clear: both;
    margin: 1.5rem 0 0.5rem 0;
  }
}

// Улучшение отображения заголовков контента
.node .content {
  h1, h2, h3, h4, h5, h6 {
    margin-top: 1.5em;
    padding-bottom: 0.5rem;
    border-bottom: 1px dotted #d8e6f3;
  }
  // Если первым элементом стоит заголовок, то отступ сверху у него выглядит лишним
  & > h1:first-child,
  & > h2:first-child,
  & > h3:first-child,
  & > h4:first-child,
  & > h5:first-child,
  & > h6:first-child {
    margin-top: 0;
  }
  h3 {
    font-weight: 500;
  }
  h4 {
    font-weight: 400;
  }
}

// Вывод блоков контрольной суммы
fieldset.checksum {
  all: revert; // Сброс fieldset до исходного состояния
  margin-bottom: 1em;
  line-height: 13px;
  background-color: #F8F8FA;
  color: gray;
  font-size: 10pt;
  font-family: monospace;
  border: 1px solid #d6dde2;

  & > legend {
    all: revert;
    display: flex;
  }
}

// Дилеры и партнёры fieldset.fieldgroup контакты на отдельных страницах
fieldset.fieldgroup {
  all: revert; // Сброс fieldset до исходного состояния
  margin:1em 0;
  padding:.5em;
  border:1px solid #d6dde2;
  overflow:hidden;
  position:relative;
  legend{
    all: revert;
    color:#0055a2;
  }
  .field-label-inline-first{
    font-weight:bold;
    display:inline;
  }
  .field-label-inline{
    font-weight:bold;
    display:inline;
  }
}

// fieldset сворачивающийся текст Drupal
fieldset.collapsible {
  all: revert;
  margin: 1em 0;
  border: 1px solid #d6dde2;
  background: linear-gradient(to bottom, #fbfbfb, #fff);
  &.collapsed {
    background: radial-gradient(circle, #e5e5e5, #fff);
  }
  & > legend {
    all: inherit;
    cursor: pointer;
    color: #5b6874;
    border-color: #9da0a3;
    background: #f8f8fa;
    font-size: 1rem;
    font-weight: 700;
    padding-bottom: 5px;
  }
  & > .collapsed-text {
    display: none;
  }
}

// Постраничный бегунок
.item-list {
  ul {
    margin: 0 0 0.75em 0;
    padding: 0;
    li {
      margin: 0 0 0.25em 1.5em;
      padding: 0;
      list-style: none;
      a.active {
        //color: #000;
      }
    }
  }
  .pager {
    clear: both;
    text-align: center;
    .pager-current {
      font-weight: bold;
    }
    li {
      display: inline;
      padding: 0.5em;
    }
  }
}

.view {
  overflow: auto; // Исправление размера левого блока при использовании правой колонки float:right
}

// Таблица сертификатов
.views-table {
  font-size: small;
  tbody, td, tfoot, th, thead, tr {
    border: 1px solid #ebf0f7;
  }
  // Исправление для маленькой таблицы сертификатов
  @media (max-width: $base-md) {
    & th:nth-child(1), & td:nth-child(1) {
      display: none;
    }
    & td {
      vertical-align: top;
    }
    & th:nth-child(3), & td:nth-child(3) {
      width: 50px;
      vertical-align: middle;
    }
    & td:nth-child(3) {
     border: none;
     display: inline-block;
     position: relative;
     top: 70px;
     vertical-align: middle;
     transform: rotate(-90deg);
    }
  }

}

// Фильтр перед таблицей сертификатов
.view-certificates .views-exposed-form {
  .form-submit {
    align-self: end;
  }
}
.views-exposed-form {
  .form-select {
    color: #00386c
  }
  .form-submit {
    //align-self: end;
    padding: 0.6rem;
    color: #00386c;
    border: 1px solid #00386c;
  }
  /*.clear-block {
    display: block;
    padding: 0 20px 0 18px;

    &:after {
      content: ".";
      display: block;
      height: 0;
      clear: both;
      visibility: hidden;
    }
  }


  .form-item {
  }

  .views-exposed-widget {
    float: left;
    padding: .5em 1em 0 0;
    margin-bottom: .5em;

    .form-submit {
      vertical-align: middle;
      color: #FFF;
      background: #0055a2;
      cursor: pointer;

      &:hover {
        background: #003869;
      }
    }
  }*/
}

// Текст внутри лицензионных соглашений
.form-textarea {
  width: 100%
}
