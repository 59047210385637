//#block-leftCol, #block-rightCol {
#block-block-32, #block-block-33 {
  background: #F7F9FC;
  background-image: url(https://cryptopro.ru/sites/default/files/images/banner/banner_header_bg.svg);
  //background-position: right top;
  background-repeat: repeat-y;
  height: 314px;
  background-size: cover;
  border: 1px solid #dee8f3;
}
#block-block-32 {
  background-position: left top;
  border-right: none;
  @at-root .leftBlock-block, .rightBlock-block, .contCol-block {
    ul {
      padding-left: 0;
      li {
        list-style: none;
        background: url(data:image/gif;base64,R0lGODlhBAAGAIABAD2C5////yH5BAEAAAEALAAAAAAEAAYAAAIIBBKGqGnnUAEAOw==) no-repeat 0 6px;
        padding: 0 0 0 12px;
        // line-height:22px;
        a {text-decoration:none}
      }
    }
    @at-root .leftBlock-title, .rightBlock-title {
      height:63px;
      border-bottom: 1px solid #dee8f3;
      display:flex;
      align-items:center;
      .leftBlock-subtitle,.rightBlock-subtitle {
        font-size:1.125rem;
        font-weight: 700;
      }
    }
    @at-root .leftBlock-content {
      margin-top:1rem;
      font-size:.875rem;
    }
  }
  @media (max-width: $base-xl) {
    border-right: 1px solid #dee8f3;
  }
}
#block-block-33 {
  background-position: right top;
  @at-root .rightBlock-block {
    @at-root .rightBlock-content {
      margin-top:1rem;
      font-size:.875rem;
    }
  }
}

#block-block-34 {
  background: #F7F9FC;
  background-image: url(https://cryptopro.ru/sites/default/files/images/banner/banner_header_bg.svg);
  background-position: center top;
  height: 314px;
  background-size: cover;
  border: 1px solid #dee8f3;
  @at-root .contBlock-header {
    display:flex!important;
    align-items:center!important;
    gap:.75rem!important;
    height:63px;
    border-bottom: 1px solid #dee8f3;
      .btn {
        background-color: rgb(247, 250, 255);
        border: 1px solid #d5dae1;
        font-size: 14px;
        font-weight: 500;
        padding-bottom: 8px;
        padding-left: 16px;
        padding-right: 16px;
        padding-top: 8px;
        &:hover {
          background-color: #ebf3fe;
          border: 1px solid #d5dae1;
        }
        &.active {
          background-color: #003869;
          color: white;
        }
      }
      @media (max-width: $base-sm) {
        & {
          height: auto;
        }
      }
    }
  @media (max-width: $base-sm) {
    & {
      height: auto;
    }
  }
}



