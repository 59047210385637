.block-quicktabs {
  background: #f1f8ff;
  border: 1px solid #dee8f3;
  .title {
    padding-top:7px;
    & > h3 {
      font-size: 1.3rem;
      font-weight:bold;
      //color:#0055a2;
      font-family: var(--bs-body-font-family);
      font-weight: 300;
    }
  }
  .quicktabs-hide{display:none;}

  .quicktabs_main {
    clear: both;
  }
  //.block .content{padding:11px 19px 17px 21px;overflow:hidden;}
  table.quicktab_block_table{
    width:100%;
    height:150px;
    tr {
      vertical-align: top;
      td.block_image{
        width:128px;
        @media (max-width: $base-md) {
          display: none;
        }
      }
    }
    ul {
      @media (max-width: $base-md) {
        padding-left: 0.8rem;
      }
    }
    ul li{
      list-style:none;
      background:url(data:image/gif;base64,R0lGODlhBAAGAIABAD2C5////yH5BAEAAAEALAAAAAAEAAYAAAIIBBKGqGnnUAEAOw==) no-repeat 0 6px; // треугольнички li
      padding:0 0 0 12px;
      margin-bottom:7px;
      line-height:16px;
      text-transform:none;
      a{
        font-size:12px;
        color:#444;
        text-decoration:none;
        &:hover{text-decoration:underline;}
      }
    }
  }

  ul.quicktabs_tabs.quicktabs-style-zen {
    clear:both;
    margin:0 0 10px 0;
    padding:0 0 36px;
    font-size:1em;
    list-style:none;
    height:21px;
    background:none;
    border-bottom:1px solid #dee8f3;
    @media (max-width: $base-sm) {
      border-bottom: none;
    }
    & li.active a,& li.active a:hover{
      text-decoration:none;
      border-width:0;
    }
    li {
      float:left;
      padding:2px;
      margin:5px 5px;
      background:#0055a2;
      display:inline;
      list-style-type:none;
      white-space:nowrap;
      a {
        font:bold 12px/170% Verdana;
        font-size-adjust:none;
        display:block;
        margin:0;
        padding:4px 17px 0px 12px;
        border-width:0;
        font-weight:bold;
        text-decoration:none;
      }
      & a,& a:hover  {
        padding:4px 17px 4px 12px;
        text-decoration:none;
        border-width:0;
        background:#0055a2;
        color:#fff;
      }
      &:hover a {
        border-width:0;
        background:none;
      }
      &.active {
        background:#003869;
        & a,& a:hover{
          text-decoration:none;
          border-width:0;
          background:#003869;
          color:#fff;
        }
      }
      & a:focus{outline:none;}
    }
  }
}