// Верхняя шапка с логотипом, слоганом, переключением языка и поиском и меню
@at-root #header {
  width: $base-md;
  margin: auto;
  @media (max-width: $base-lg) {
    & {
      max-width: $base-md;
      width: 100%;
    }
  }
  .mobile-slogan {
    display: none;
    @media (max-width: $base-sm) {
      & {
        display: block;
        padding: 0.5em 0 0.3em;
        background: #f1f8ff;
        color: #717171;
        text-transform: uppercase;
        letter-spacing: .1rem;
        font-size: 0.7rem;
        text-align: center;
      }
    }
  }
  #burger-icon {
    position: absolute;
    top: 50px;
    left: $padding/2;
  }
  // Логотип, слоган, язык, поиск
  .head-row1 {
    width:100%;
    overflow:hidden;
    padding: $padding $padding/2;
    // Логотип, слоган
    .head-row1-col1{
      float:left;
      // width:560px;
      .logo{
        float:left;
        margin-right:20px;
        width:200px;
        height:calc( 200px / 3 ); // Нужно для IE
        @media (max-width: $base-sm) {
          margin-right: 0;
          width:100px;
          height:calc( 100px / 3 );
        }
      }
      .slogan{
        float:left;
        padding:10px 0 8px 20px;
        color:#0055a2;
        font-size:20px;
        line-height: 24px;
        border-left:3px solid #0055a2;
        @media (max-width: $base-sm) {
          display: none;
        }
      }
      @media (max-width: $base-lg) {
        // width:auto;
        // display: flex;
        // justify-content: center;
      }
      @media (max-width: $base-sm) {
        //display: flex;
        //justify-content: center;
      }
    }
    // Поиск
    .head-row1-col2{
      float:right;
      // Язык
      @at-root #lang {
        font-weight:700;
        color:gray;
        display: inline-block;
        margin-right: 16px;
        position: relative;
        top: 2px; // Небольшое смещение, иначе не по центру
        a{
          text-decoration:none;
          color:#0055a2;
          &:hover{color:#003869;}
        }
        @media (max-width: $base-sm) {
          & {
            display: none; // Скрываем язык
          }
        }
      }
      .search-box{
        padding-top:20px;
        display: inline-block;
        @at-root #search-theme-form{
          .form-submit{
            border:none;
            width:69px;
            height:21px;
            vertical-align:middle;
            color:#FFF;
            font-weight:bold;
            font-size:11px;
            padding-bottom:3px;
            line-height:15px;
          }
          .form-text{
            box-sizing: unset;
            vertical-align:middle;
            width:186px;
            font-size:12px;
            font-weight:normal;
            color:#1d7bbd;
            height:17px;
            background:#FFF;
            border:1px solid #c1c2c9;
            margin-top: 0;
            margin-right:8px;
            padding:2px 0 0 5px;
            line-height: normal;
          }
          .form-submit,#user-login-form .form-submit{
            background:#0055a2;
            cursor:pointer;
            padding-top:4px;
          }
          .form-submit:hover,#user-login-form .form-submit:hover{
            background:#003869;
          }
        }
      }
      @media (max-width: $base-md) {
        & {
          display: none; // Скрываем поиск
        }
      }
    }
    @media (max-width: $base-md) {
      display: flex;
      justify-content: center;
    }
    @media (max-width: $base-sm) {
      padding: $padding/2 $padding/2;
    }
  }
  .mobile-menu {
    display: none;
    & > .btn-group {
      width: calc(100% - #{$padding});
      display: flex;
      margin: auto;
      & > a {
        border-color: #ddd;
        background: #f6f6f6;
      }
    }
    @media (max-width: $base-sm) {
      display: block;
    }
    // Строго первый уровень меню и ниже
    @at-root #offcanvasMenuBody > ul.menu {
      margin-top: -20px;
      margin-left: 10px;
      li {
        padding: 0 0 4px;
        overflow: inherit;
        position: relative;
        &.expanded {
          padding-bottom: 0;
          ul {
            padding-top: 1px;
            margin: 3px 0 0 10px;
          }
        }
        a {
          text-decoration: none;
          padding-left: 11px;
          display: block;
          color: #444;
          //font-size: 13px;
          // border-bottom: 1px solid #d8e6f3;
          &:hover, &.active {
            color: #FFF;
            background: #0055a2;
          }
        }
      }
      &, ul {
        border-left: 1px dotted grey;
        padding-left: 5px
      }
      &>li:before, ul>li:before {
        border-bottom: 1px dotted grey;
        content: "";
        display: block;
        left: -5px;
        position: relative;
        top: 13px;
        width: 12px;
      }
      &>li:last-child:after, ul>li:last-child:after {
        background: #fff;
        bottom: 0;
        content: "";
        display: block;
        left: -10px;
        position: absolute;
        top: 14px;
        width: 8px;
      }
      &>li, ul>li {
        list-style: none;
      }
      &>li>a {
        font-size: 1.2rem;
        font-weight: 700;
        color: #0055a2;
      }
      &>li>ul>li>a {
        font-weight: 500;
      }
    }
  }
  // Главное меню
  .head-row2{
    height:41px;
    width:100%;
    overflow:hidden;
    // Главное меню
    @at-root #suckerfishmenu {
      overflow:hidden;
      padding:0;
      // background:#e6eff8;
      // Общие правила всех уровней
      & ul.menu {
        padding: 0;
        z-index: 200;
        margin: 0;
        list-style: none;
        // Все <li> в меню
        & li{
          display: inline;
          float: left;
          margin: 0;
          padding: 0;
          &:hover > ul.menu {visibility:visible;} // Появление второго и третьего уровня меню
          &:hover, &.active-trail {
            background: #0055a2;
            & > a {
              color:#fff;
            }
          }
        }
        // Все <a> в меню
        a {
          text-decoration:none;
          color: #000;
          &:hover {
            color: #fff;
          }
        }
      }
      // Строго первый уровень меню и ниже
      & > ul.menu{
        text-align:center;
        width:100%;
        // Первые элементы li
        & > li{
          &:hover, &.active-trail {
            margin-left: -1px; // Наезд на фоновую картинку разделителя
            padding-left: 1px; // Возврат текста на место
          }
          background: url(data:image/gif;base64,R0lGODlhAQAKAIAAAKertwAAACH5BAAAAAAALAAAAAABAAoAAAIDhI8FADs=) no-repeat right 16px; // Чёрточка между пунктами меню
          & > a {
            font-size:13px;
            line-height: 15px;
            text-decoration:none;
            text-align:center;
            display:block;
            padding:13px 0 18px 0;
            width:99px;
          }
          // Все ниже первого
          & ul.menu{
            background-color:#e6eff8;
            position:absolute;
            width:242px;
            visibility:hidden; // Скрытие элемента, если не активно
            margin-left: -1px; // Смещение из-за смещения верхнего меню для наезда на разделитель
            li {
              float:left;
            }
            a {
              text-align:left;
              display:block;
              padding-left:10px;
            }
          }
          // Строго второй уровень меню и ниже
          & > ul.menu{
            margin-top:-6px;
            a {
              width: 242px;
              font-size:12px;
              line-height:24px;
              border:1px solid #fff;
              border-top:none;
              // Текст только в одну строку
              text-wrap: nowrap;
              overflow: hidden;
              text-overflow: ellipsis;
            }
            // Вторые элементы li
            & > li{
              &.first > a { border-top:2px solid #0055a2; } // Появление подложки над вторым уровнем меню
              // Строго третий уровень меню и ниже
              & > ul.menu{
                border-left: 2px solid #0055a2;
                margin:-25px 0px 0px 241px; // Отступ вправо третьего уровня меню
                & > li{
                }
              }
            }
            @media (max-width: $base-lg) {
              & {
                display: none; // При недостаточном размере экрана вторая группа меню не раскрывается, иначе это вызывает лишнюю прокрутку
              }
            }
          }
        }
      }
    }
    @media (max-width: $base-md) {
      & {
        height: auto; // При недостаточном размере экрана вторая группа меню не раскрывается, иначе это вызывает лишнюю прокрутку
      }
      #suckerfishmenu > .menu {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
      }
    }
    @media (max-width: $base-sm) {
      display: none;
    }
  }


  // Баннер
  .head-row3{
    display: none; // todo: отключение или переделка
    width:100%;
    padding: 0 $padding/2;
    overflow:hidden;
    // Частные случаи: Promo баннер
    @at-root #promo {
      background: #f1f5fe;
      box-sizing: unset;
      border-bottom: 4px solid #d8e6f3;

      @keyframes promofade {
        0%	{ opacity: 0; z-index:99; }
        10%   { opacity: 1; }
        55%   { opacity: 1; }
        65%   { opacity: 0; z-index:98; }
        100%  { opacity: 0; }
      }

      &.fadein {
        height: 153px;
        position:relative;
        a {
          position:absolute;
          width:100%;
          left:0;
          right:0;
          opacity:0;
          animation-name: promofade;
          animation-duration: 24s;
          animation-iteration-count: infinite;
          &:nth-child(1) { animation-delay: 0s; }
          &:nth-child(2) { animation-delay: 12s; }
        }
      }
      &.fadein_page {
        height: 100px;
        position:relative;
        a {
          position:absolute;
          width:100%;
          left:0;
          right:0;
          opacity:0;
          z-index:98;
          &:nth-child(1) { animation-delay: 0s; }
          &:nth-child(2) { animation-delay: 12s; }
        }
      }
      /*#promo > a {
          position: absolute;
          opacity: 0;
      }*/
    }
  }
}

.header-divider {
  //width: 1060px;
  border-bottom: 1px solid #e2e2e2;
}
