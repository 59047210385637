// todo: не нужно ли тут уменьшить на 10px?
$base-xxl: 1660px;
$base-xl: 1360px;
$base-lg: 1060px;
$base-md: 950px;
$base-sm: 760px;
$base-min: 360px;
$padding: 35px; // стандартный отступ в блоках
$cont-main: 690px + 2*$padding;
$main-grow: 228px;
$cont-main-grow: $cont-main + $main-grow;
$cont-block: 300px;
$cont-head-space: 2rem;
$cp-font-family-special-header: 'Open Sans Condensed', Roboto, system-ui, -apple-system, "Segoe UI", "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";

body {
  min-width: $base-min;
  box-shadow: inset 0 -50px 200px -50px #ebebebbe;
}

#left-col, #right-col {
  min-width: $cont-block;
  width: calc((100% - #{$cont-main})/2); // Размер основного контента 690px + 4rem отступов и по середине (пополам)
  & > .ind {
    width: $cont-block; // Размер aside боков
  }
}

#left-col {
  float: left;
  & > .ind {
    padding-top: $padding;
    float: right;
    border-right: 1px solid #e2e2e2;
  }
}

#right-col {
  float: right;
  & > .ind {
    padding-top: $padding;
  }
}

#cont-col {
  & > .ind {
    max-width: $cont-main; // Размер + отступы
    padding: $padding;
    margin: 0 auto;
    display: block; // IE очень хочет это видеть
  }
}

.ind {
  overflow: hidden;
}

@media (max-width: $base-xxl) {

}

@media (max-width: $base-xl) {
  #left-col, #right-col {
    margin-right: calc((100% - #{$cont-main} - #{$cont-block})/2);
    & > .ind {
    }
  }
  #left-col {
    float: right;
    clear: right;
    & > .ind {
      border-right: none;
    }
  }
  #right-col {
  }
  #cont-col {
    display: flex;
    justify-content: right;
    & > .ind {
      margin: 0;
    }
  }
}

@media (max-width: $base-lg) {
  #left-col, #right-col {
    margin-right: 0;
  }
}

@media (max-width: $base-md) {
  #cont {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  #left-col, #right-col {
    order: 2;
    margin: 0;
  }
  #cont-col {
    order: 1;
    display: flex;
    justify-content: center;
    & > .ind {
      padding: $padding $padding/2;
    }
  }
}

@media (max-width: $base-sm) {
  #cont-col {
    width: 100%; // Для IE
  }
}

// Модификатор для расширенного блока NGate 690px + 228px
.main-grow {
  #cont-col {
    & > .ind {
      max-width: $cont-main-grow;
    }
  }
  #left-col, #right-col {
    width: calc((100% - #{$cont-main-grow})/2);
  }
  @media (max-width: $base-xl + $main-grow), (max-width: $base-xl) {
    #left-col, #right-col {
      margin-right: calc((100% - #{$cont-main-grow} - #{$cont-block})/2);
    }
    #left-col {
      float: right;
      clear: right;
      & > .ind {
        border-right: none;
      }
    }
    #right-col {
    }
    #cont-col {
      display: flex;
      justify-content: right;
      & > .ind {
        margin: 0;
      }
    }
  }
  @media (max-width: $base-lg + $main-grow) {
    @at-root #cont.main-grow {
      max-width: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
    }
    #cont-col {
      max-width: 100%;
      padding: 0;
      order: 1;
      display: flex;
      justify-content: center;
    }
    #left-col, #right-col {
      order: 2;
      margin: 0;
    }
  }
  @media (max-width: $base-sm + $main-grow) {
    #cont-col {
      & > .ind {
        width: 100%;
      }
    }
  }
}
