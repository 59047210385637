@charset "UTF-8";
@import 'https://cryptopro.ru/static/fonts/fonts.css';
.font-osc {
  font-family: 'Open Sans Condensed',sans-serif;
}

body {
  min-width: 360px;
  box-shadow: inset 0 -50px 200px -50px #ebebebbe;
}

#left-col, #right-col {
  min-width: 300px;
  width: calc((100% - 760px)/2);
}

#left-col > .ind, #right-col > .ind {
  width: 300px;
}

#left-col {
  float: left;
}

#left-col > .ind {
  padding-top: 35px;
  float: right;
  border-right: 1px solid #e2e2e2;
}

#right-col {
  float: right;
}

#right-col > .ind {
  padding-top: 35px;
}

#cont-col > .ind {
  max-width: 760px;
  padding: 35px;
  margin: 0 auto;
  display: block;
}

.ind {
  overflow: hidden;
}

@media (max-width: 1360px) {
  #left-col, #right-col {
    margin-right: calc((100% - 760px - 300px)/2);
  }
  #left-col {
    float: right;
    clear: right;
  }
  #left-col > .ind {
    border-right: none;
  }
  #cont-col {
    display: flex;
    justify-content: right;
  }
  #cont-col > .ind {
    margin: 0;
  }
}

@media (max-width: 1060px) {
  #left-col, #right-col {
    margin-right: 0;
  }
}

@media (max-width: 950px) {
  #cont {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  #left-col, #right-col {
    order: 2;
    margin: 0;
  }
  #cont-col {
    order: 1;
    display: flex;
    justify-content: center;
  }
  #cont-col > .ind {
    padding: 35px 17.5px;
  }
}

@media (max-width: 760px) {
  #cont-col {
    width: 100%;
  }
}

.main-grow #cont-col > .ind {
  max-width: 988px;
}

.main-grow #left-col, .main-grow #right-col {
  width: calc((100% - 988px)/2);
}

@media (max-width: 1588px), (max-width: 1360px) {
  .main-grow #left-col, .main-grow #right-col {
    margin-right: calc((100% - 988px - 300px)/2);
  }
  .main-grow #left-col {
    float: right;
    clear: right;
  }
  .main-grow #left-col > .ind {
    border-right: none;
  }
  .main-grow #cont-col {
    display: flex;
    justify-content: right;
  }
  .main-grow #cont-col > .ind {
    margin: 0;
  }
}

@media (max-width: 1288px) {
  #cont.main-grow {
    max-width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .main-grow #cont-col {
    max-width: 100%;
    padding: 0;
    order: 1;
    display: flex;
    justify-content: center;
  }
  .main-grow #left-col, .main-grow #right-col {
    order: 2;
    margin: 0;
  }
}

@media (max-width: 988px) {
  .main-grow #cont-col > .ind {
    width: 100%;
  }
}

#content-header .breadcrumb {
  padding: 0 0 7px;
  color: #484848;
  font-size: 11px;
  font-weight: normal;
  text-align: left;
  border-bottom: 1px solid #d1d9e0;
  margin-bottom: 0;
  line-height: 13px;
  display: block;
}

#content-header .breadcrumb a {
  color: #0055a2;
  text-decoration: none;
  padding: 0 5px 0 2px;
  text-transform: none;
  vertical-align: middle;
  font-weight: normal;
}

#content-header .breadcrumb a:hover {
  text-decoration: underline;
  color: #000;
}

#content-header .breadcrumb a:nth-child(2) {
  padding-left: 5px;
}

#content-header > .title > h3 {
  font-family: "Open Sans Condensed", Roboto, system-ui, -apple-system, "Segoe UI", "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  color: var(--bs-body-color);
  font-size: 1.75rem;
}

#main .messages.error {
  border: 1px solid red;
  padding-top: 15px;
  color: #f74545;
  margin-top: 20px;
}

.node {
  margin-top: 1.5rem;
  overflow-wrap: anywhere;
}

.node > .editorial {
  display: inline-block;
  padding: 0.375rem 0.75rem;
  font-size: 0.75rem;
  font-weight: 500;
  line-height: normal;
  vertical-align: baseline;
  margin-bottom: 1rem !important;
  color: rgba(var(--bs-secondary-rgb), 1) !important;
  background-color: rgba(var(--bs-primary-dark-rgb), 1) !important;
}

.node > .editorial:empty {
  display: none;
}

.node .content {
  overflow: hidden;
}

.node .content > img, .node .content > p > img, .node .content > p > a > img, .node .content > a > img {
  /* Если применить ко всем изображениям "& img", то это вызывает ошибку вёрстки тут https://cryptopro.ru/about/licenses */
  max-width: 100%;
  height: auto;
}

.node .content ul.cp-list {
  margin-top: 0.7em;
  margin-left: 0.7em;
}

.node .content ul.cp-list li {
  margin-bottom: 0.7em;
}

.node .content > ul {
  padding-left: 0;
}

.node .content > ul li {
  list-style: none;
  background: url(data:image/gif;base64,R0lGODlhBAAGAIABAD2C5////yH5BAEAAAEALAAAAAAEAAYAAAIIBBKGqGnnUAEAOw==) no-repeat 0 6px;
  padding-left: 12px;
}

img.imagefield-field_dealer_logo {
  max-width: 200px;
  height: auto;
}

table.dealer td:nth-child(1) > img {
  max-width: 200px;
  height: auto;
}

.node .content iframe {
  max-width: 100%;
}

.node .content .news150 {
  float: left;
  margin: 0 20px 20px 0;
  max-width: 150px;
}

.node .content .news280 {
  float: left;
  margin: 0 20px 20px 0;
  max-width: 150px;
}

#main > .node > .content .news280 {
  max-width: 280px;
}

@media (max-width: 760px) {
  #main > .node > .content .news280 {
    max-width: 150px;
  }
}

.node .content .links-indent {
  padding: 10px 0 0 0;
  overflow: hidden;
}

.node .content .links-indent ul.links {
  padding-left: 0;
}

.node .content .links-indent ul.links li {
  list-style: none;
}

.node .content .links-indent ul.links li a {
  padding: 5px 10px;
  background: #0055a2;
  color: #fff;
  text-decoration: none;
}

.node .content .links-indent ul.links li a img.print-icon-margin {
  margin-right: 7px;
}

.views-row {
  border-bottom: 1px solid #e2e2e2;
  margin-bottom: 2rem;
}

.views-row:last-child {
  margin-bottom: 1rem;
}

.views-row h4.title a {
  text-decoration: none;
  color: #0055a2;
}

.views-row h4.title a:hover {
  text-decoration: none;
  color: #0f3262;
}

.views-row .read-more {
  clear: both;
  margin: 1.5rem 0 0.5rem 0;
}

.node .content h1, .node .content h2, .node .content h3, .node .content h4, .node .content h5, .node .content h6 {
  margin-top: 1.5em;
  padding-bottom: 0.5rem;
  border-bottom: 1px dotted #d8e6f3;
}

.node .content > h1:first-child,
.node .content > h2:first-child,
.node .content > h3:first-child,
.node .content > h4:first-child,
.node .content > h5:first-child,
.node .content > h6:first-child {
  margin-top: 0;
}

.node .content h3 {
  font-weight: 500;
}

.node .content h4 {
  font-weight: 400;
}

fieldset.checksum {
  all: revert;
  margin-bottom: 1em;
  line-height: 13px;
  background-color: #F8F8FA;
  color: gray;
  font-size: 10pt;
  font-family: monospace;
  border: 1px solid #d6dde2;
}

fieldset.checksum > legend {
  all: revert;
  display: flex;
}

fieldset.fieldgroup {
  all: revert;
  margin: 1em 0;
  padding: .5em;
  border: 1px solid #d6dde2;
  overflow: hidden;
  position: relative;
}

fieldset.fieldgroup legend {
  all: revert;
  color: #0055a2;
}

fieldset.fieldgroup .field-label-inline-first {
  font-weight: bold;
  display: inline;
}

fieldset.fieldgroup .field-label-inline {
  font-weight: bold;
  display: inline;
}

fieldset.collapsible {
  all: revert;
  margin: 1em 0;
  border: 1px solid #d6dde2;
  background: linear-gradient(to bottom, #fbfbfb, #fff);
}

fieldset.collapsible.collapsed {
  background: radial-gradient(circle, #e5e5e5, #fff);
}

fieldset.collapsible > legend {
  all: inherit;
  cursor: pointer;
  color: #5b6874;
  border-color: #9da0a3;
  background: #f8f8fa;
  font-size: 1rem;
  font-weight: 700;
  padding-bottom: 5px;
}

fieldset.collapsible > .collapsed-text {
  display: none;
}

.item-list ul {
  margin: 0 0 0.75em 0;
  padding: 0;
}

.item-list ul li {
  margin: 0 0 0.25em 1.5em;
  padding: 0;
  list-style: none;
}

.item-list .pager {
  clear: both;
  text-align: center;
}

.item-list .pager .pager-current {
  font-weight: bold;
}

.item-list .pager li {
  display: inline;
  padding: 0.5em;
}

.view {
  overflow: auto;
}

.views-table {
  font-size: small;
}

.views-table tbody, .views-table td, .views-table tfoot, .views-table th, .views-table thead, .views-table tr {
  border: 1px solid #ebf0f7;
}

@media (max-width: 950px) {
  .views-table th:nth-child(1), .views-table td:nth-child(1) {
    display: none;
  }
  .views-table td {
    vertical-align: top;
  }
  .views-table th:nth-child(3), .views-table td:nth-child(3) {
    width: 50px;
    vertical-align: middle;
  }
  .views-table td:nth-child(3) {
    border: none;
    display: inline-block;
    position: relative;
    top: 70px;
    vertical-align: middle;
    transform: rotate(-90deg);
  }
}

.view-certificates .views-exposed-form .form-submit {
  align-self: end;
}

.views-exposed-form {
  /*.clear-block {
    display: block;
    padding: 0 20px 0 18px;

    &:after {
      content: ".";
      display: block;
      height: 0;
      clear: both;
      visibility: hidden;
    }
  }


  .form-item {
  }

  .views-exposed-widget {
    float: left;
    padding: .5em 1em 0 0;
    margin-bottom: .5em;

    .form-submit {
      vertical-align: middle;
      color: #FFF;
      background: #0055a2;
      cursor: pointer;

      &:hover {
        background: #003869;
      }
    }
  }*/
}

.views-exposed-form .form-select {
  color: #00386c;
}

.views-exposed-form .form-submit {
  padding: 0.6rem;
  color: #00386c;
  border: 1px solid #00386c;
}

.form-textarea {
  width: 100%;
}

#header {
  width: 950px;
  margin: auto;
}

@media (max-width: 1060px) {
  #header {
    max-width: 950px;
    width: 100%;
  }
}

#header .mobile-slogan {
  display: none;
}

@media (max-width: 760px) {
  #header .mobile-slogan {
    display: block;
    padding: 0.5em 0 0.3em;
    background: #f1f8ff;
    color: #717171;
    text-transform: uppercase;
    letter-spacing: .1rem;
    font-size: 0.7rem;
    text-align: center;
  }
}

#header #burger-icon {
  position: absolute;
  top: 50px;
  left: 17.5px;
}

#header .head-row1 {
  width: 100%;
  overflow: hidden;
  padding: 35px 17.5px;
}

#header .head-row1 .head-row1-col1 {
  float: left;
}

#header .head-row1 .head-row1-col1 .logo {
  float: left;
  margin-right: 20px;
  width: 200px;
  height: calc( 200px / 3);
}

@media (max-width: 760px) {
  #header .head-row1 .head-row1-col1 .logo {
    margin-right: 0;
    width: 100px;
    height: calc( 100px / 3);
  }
}

#header .head-row1 .head-row1-col1 .slogan {
  float: left;
  padding: 10px 0 8px 20px;
  color: #0055a2;
  font-size: 20px;
  line-height: 24px;
  border-left: 3px solid #0055a2;
}

@media (max-width: 760px) {
  #header .head-row1 .head-row1-col1 .slogan {
    display: none;
  }
}

#header .head-row1 .head-row1-col2 {
  float: right;
}

#lang {
  font-weight: 700;
  color: gray;
  display: inline-block;
  margin-right: 16px;
  position: relative;
  top: 2px;
}

#lang a {
  text-decoration: none;
  color: #0055a2;
}

#lang a:hover {
  color: #003869;
}

@media (max-width: 760px) {
  #lang {
    display: none;
  }
}

#header .head-row1 .head-row1-col2 .search-box {
  padding-top: 20px;
  display: inline-block;
}

#search-theme-form .form-submit {
  border: none;
  width: 69px;
  height: 21px;
  vertical-align: middle;
  color: #FFF;
  font-weight: bold;
  font-size: 11px;
  padding-bottom: 3px;
  line-height: 15px;
}

#search-theme-form .form-text {
  box-sizing: unset;
  vertical-align: middle;
  width: 186px;
  font-size: 12px;
  font-weight: normal;
  color: #1d7bbd;
  height: 17px;
  background: #FFF;
  border: 1px solid #c1c2c9;
  margin-top: 0;
  margin-right: 8px;
  padding: 2px 0 0 5px;
  line-height: normal;
}

#search-theme-form .form-submit, #search-theme-form #user-login-form .form-submit {
  background: #0055a2;
  cursor: pointer;
  padding-top: 4px;
}

#search-theme-form .form-submit:hover, #search-theme-form #user-login-form .form-submit:hover {
  background: #003869;
}

@media (max-width: 950px) {
  #header .head-row1 .head-row1-col2 {
    display: none;
  }
}

@media (max-width: 950px) {
  #header .head-row1 {
    display: flex;
    justify-content: center;
  }
}

@media (max-width: 760px) {
  #header .head-row1 {
    padding: 17.5px 17.5px;
  }
}

#header .mobile-menu {
  display: none;
}

#header .mobile-menu > .btn-group {
  width: calc(100% - 35px);
  display: flex;
  margin: auto;
}

#header .mobile-menu > .btn-group > a {
  border-color: #ddd;
  background: #f6f6f6;
}

@media (max-width: 760px) {
  #header .mobile-menu {
    display: block;
  }
}

#offcanvasMenuBody > ul.menu {
  margin-top: -20px;
  margin-left: 10px;
}

#offcanvasMenuBody > ul.menu li {
  padding: 0 0 4px;
  overflow: inherit;
  position: relative;
}

#offcanvasMenuBody > ul.menu li.expanded {
  padding-bottom: 0;
}

#offcanvasMenuBody > ul.menu li.expanded ul {
  padding-top: 1px;
  margin: 3px 0 0 10px;
}

#offcanvasMenuBody > ul.menu li a {
  text-decoration: none;
  padding-left: 11px;
  display: block;
  color: #444;
}

#offcanvasMenuBody > ul.menu li a:hover, #offcanvasMenuBody > ul.menu li a.active {
  color: #FFF;
  background: #0055a2;
}

#offcanvasMenuBody > ul.menu, #offcanvasMenuBody > ul.menu ul {
  border-left: 1px dotted grey;
  padding-left: 5px;
}

#offcanvasMenuBody > ul.menu > li:before, #offcanvasMenuBody > ul.menu ul > li:before {
  border-bottom: 1px dotted grey;
  content: "";
  display: block;
  left: -5px;
  position: relative;
  top: 13px;
  width: 12px;
}

#offcanvasMenuBody > ul.menu > li:last-child:after, #offcanvasMenuBody > ul.menu ul > li:last-child:after {
  background: #fff;
  bottom: 0;
  content: "";
  display: block;
  left: -10px;
  position: absolute;
  top: 14px;
  width: 8px;
}

#offcanvasMenuBody > ul.menu > li, #offcanvasMenuBody > ul.menu ul > li {
  list-style: none;
}

#offcanvasMenuBody > ul.menu > li > a {
  font-size: 1.2rem;
  font-weight: 700;
  color: #0055a2;
}

#offcanvasMenuBody > ul.menu > li > ul > li > a {
  font-weight: 500;
}

#header .head-row2 {
  height: 41px;
  width: 100%;
  overflow: hidden;
}

#suckerfishmenu {
  overflow: hidden;
  padding: 0;
}

#suckerfishmenu ul.menu {
  padding: 0;
  z-index: 200;
  margin: 0;
  list-style: none;
}

#suckerfishmenu ul.menu li {
  display: inline;
  float: left;
  margin: 0;
  padding: 0;
}

#suckerfishmenu ul.menu li:hover > ul.menu {
  visibility: visible;
}

#suckerfishmenu ul.menu li:hover, #suckerfishmenu ul.menu li.active-trail {
  background: #0055a2;
}

#suckerfishmenu ul.menu li:hover > a, #suckerfishmenu ul.menu li.active-trail > a {
  color: #fff;
}

#suckerfishmenu ul.menu a {
  text-decoration: none;
  color: #000;
}

#suckerfishmenu ul.menu a:hover {
  color: #fff;
}

#suckerfishmenu > ul.menu {
  text-align: center;
  width: 100%;
}

#suckerfishmenu > ul.menu > li {
  background: url(data:image/gif;base64,R0lGODlhAQAKAIAAAKertwAAACH5BAAAAAAALAAAAAABAAoAAAIDhI8FADs=) no-repeat right 16px;
}

#suckerfishmenu > ul.menu > li:hover, #suckerfishmenu > ul.menu > li.active-trail {
  margin-left: -1px;
  padding-left: 1px;
}

#suckerfishmenu > ul.menu > li > a {
  font-size: 13px;
  line-height: 15px;
  text-decoration: none;
  text-align: center;
  display: block;
  padding: 13px 0 18px 0;
  width: 99px;
}

#suckerfishmenu > ul.menu > li ul.menu {
  background-color: #e6eff8;
  position: absolute;
  width: 242px;
  visibility: hidden;
  margin-left: -1px;
}

#suckerfishmenu > ul.menu > li ul.menu li {
  float: left;
}

#suckerfishmenu > ul.menu > li ul.menu a {
  text-align: left;
  display: block;
  padding-left: 10px;
}

#suckerfishmenu > ul.menu > li > ul.menu {
  margin-top: -6px;
}

#suckerfishmenu > ul.menu > li > ul.menu a {
  width: 242px;
  font-size: 12px;
  line-height: 24px;
  border: 1px solid #fff;
  border-top: none;
  text-wrap: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

#suckerfishmenu > ul.menu > li > ul.menu > li.first > a {
  border-top: 2px solid #0055a2;
}

#suckerfishmenu > ul.menu > li > ul.menu > li > ul.menu {
  border-left: 2px solid #0055a2;
  margin: -25px 0px 0px 241px;
}

@media (max-width: 1060px) {
  #suckerfishmenu > ul.menu > li > ul.menu {
    display: none;
  }
}

@media (max-width: 950px) {
  #header .head-row2 {
    height: auto;
  }
  #header .head-row2 #suckerfishmenu > .menu {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
  }
}

@media (max-width: 760px) {
  #header .head-row2 {
    display: none;
  }
}

#header .head-row3 {
  display: none;
  width: 100%;
  padding: 0 17.5px;
  overflow: hidden;
}

#promo {
  background: #f1f5fe;
  box-sizing: unset;
  border-bottom: 4px solid #d8e6f3;
  /*#promo > a {
          position: absolute;
          opacity: 0;
      }*/
}

@keyframes promofade {
  0% {
    opacity: 0;
    z-index: 99;
  }
  10% {
    opacity: 1;
  }
  55% {
    opacity: 1;
  }
  65% {
    opacity: 0;
    z-index: 98;
  }
  100% {
    opacity: 0;
  }
}

#promo.fadein {
  height: 153px;
  position: relative;
}

#promo.fadein a {
  position: absolute;
  width: 100%;
  left: 0;
  right: 0;
  opacity: 0;
  animation-name: promofade;
  animation-duration: 24s;
  animation-iteration-count: infinite;
}

#promo.fadein a:nth-child(1) {
  animation-delay: 0s;
}

#promo.fadein a:nth-child(2) {
  animation-delay: 12s;
}

#promo.fadein_page {
  height: 100px;
  position: relative;
}

#promo.fadein_page a {
  position: absolute;
  width: 100%;
  left: 0;
  right: 0;
  opacity: 0;
  z-index: 98;
}

#promo.fadein_page a:nth-child(1) {
  animation-delay: 0s;
}

#promo.fadein_page a:nth-child(2) {
  animation-delay: 12s;
}

.header-divider {
  border-bottom: 1px solid #e2e2e2;
}

#footer {
  clear: both;
  font-weight: normal;
  font-size: 14px;
}

#footer .privacy-policy {
  font-size: 0.75em;
  line-height: 20px;
  text-decoration: none;
}

#footer a {
  color: #46535b;
  text-decoration: underline;
}

#footer a:hover {
  text-decoration: none;
}

#footer .foot {
  text-align: center;
  margin: 0 auto;
  width: 950px;
  padding: 30px 0px 30px 0px;
}

@media (max-width: 1060px) {
  #footer .foot {
    width: 100%;
  }
}

#footer img.t_logo {
  width: 20px;
  height: 20px;
  position: relative;
  margin-bottom: -5px;
  filter: grayscale(1);
}

.block {
  margin-bottom: 17.5px;
  color: #363638;
  position: relative;
  overflow: hidden;
}

.block > .title > h3 {
  color: #39464b;
  font-size: 20px;
  font-family: "Open Sans Condensed", Roboto, system-ui, -apple-system, "Segoe UI", "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  padding: 10px 0 0 21px;
  margin: 0;
}

.block .menu-name-primary-links ul.menu {
  padding-left: 20px;
}

.block > .content {
  color: #444;
  line-height: 22px;
  padding: 11px 19px 17px 21px;
  overflow: hidden;
}

.block > .content > ul li {
  padding: 0 0 0 12px;
  list-style: none;
  line-height: 16px;
  color: #444;
  margin-left: 0;
  text-transform: none;
  font-weight: normal;
}

.block > .content > ul li a {
  color: #444;
  text-transform: none;
  text-decoration: none;
}

.block > .content > ul li a:hover {
  text-decoration: underline;
}

.block.block-user ul.menu {
  padding: 0;
}

.block.block-user ul.menu > li {
  background: url(data:image/gif;base64,R0lGODlhBAAGAIABAD2C5////yH5BAEAAAEALAAAAAAEAAYAAAIIBBKGqGnnUAEAOw==) no-repeat 0 9px;
  font-size: 13px;
  padding-top: 5px;
  padding-bottom: 5px;
}

.block.block-user ul.menu > li:hover {
  background: #0055a2;
}

.block.block-user ul.menu > li:hover a {
  text-decoration: none;
  color: #FFF;
}

.block.block-menu_block ul.menu {
  text-align: left;
  padding-left: 0;
  padding-bottom: 0;
  margin-bottom: 0;
  width: 100%;
}

.block.block-menu_block ul.menu li {
  padding: 0 0 4px;
  overflow: inherit;
  position: relative;
}

.block.block-menu_block ul.menu li.expanded {
  padding-bottom: 0;
}

.block.block-menu_block ul.menu li.expanded ul {
  padding-top: 1px;
  margin: 3px 0 0 10px;
}

.block.block-menu_block ul.menu li a {
  text-decoration: none;
  padding-left: 11px;
  display: block;
  color: #444;
  font-size: 13px;
}

.block.block-menu_block ul.menu li a:hover, .block.block-menu_block ul.menu li a.active {
  color: #FFF;
  background: #0055a2;
}

.block.block-menu_block ul.menu, .block.block-menu_block ul.menu ul {
  border-left: 1px dotted grey;
  padding-left: 5px;
}

.block.block-menu_block ul.menu > li:before, .block.block-menu_block ul.menu ul > li:before {
  border-bottom: 1px dotted grey;
  content: "";
  display: block;
  left: -5px;
  position: relative;
  top: 13px;
  width: 12px;
}

.block.block-menu_block ul.menu > li:last-child:after, .block.block-menu_block ul.menu ul > li:last-child:after {
  background: #fff;
  bottom: 0;
  content: "";
  display: block;
  left: -10px;
  position: absolute;
  top: 14px;
  width: 8px;
}

.block.block-menu_block ul.menu > li, .block.block-menu_block ul.menu ul > li {
  list-style: none;
}

#block-block-4 .content ul.menu, #block-block-15 .content ul.menu {
  padding-left: 0;
  padding-bottom: 0;
  width: 100%;
}

#block-block-4 .content ul.menu .cp_linktext, #block-block-15 .content ul.menu .cp_linktext {
  position: absolute;
  top: 50%;
  transform: translate(0, -50%);
}

#block-block-4 .content ul.menu li, #block-block-15 .content ul.menu li {
  padding: 0 0 4px 0;
  list-style: none;
  margin: 0;
  line-height: 21px;
  overflow: hidden;
}

#block-block-4 .content ul.menu li a, #block-block-15 .content ul.menu li a {
  font-size: 12px;
  text-decoration: none;
  font-weight: normal;
  padding-left: 11px;
  overflow: hidden;
  display: block;
  color: #0055a2;
  position: relative;
}

#block-block-4 .content ul.menu li a:hover, #block-block-15 .content ul.menu li a:hover {
  text-decoration: none;
  color: #FFF;
  background: #0055a2;
}

#user-login-form {
  text-align: left;
  color: #FFF;
  font-size: 12px;
}

#user-login-form .form-required {
  color: #999999;
}

#user-login-form .form-item {
  margin-top: 0;
  margin-bottom: 10px;
}

#user-login-form .form-item label {
  color: #7a7c88;
  font-size: 12px;
  font-weight: normal;
  line-height: normal;
}

#user-login-form .form-item input#edit-name, #user-login-form .form-item input#edit-pass {
  width: 224px;
  color: #6f6f6f;
  background: #FFF;
  border: 1px solid #c1c2c9;
  padding: 2px 0 0 5px;
}

#user-login-form .form-submit {
  border: none;
  width: 64px;
  height: 29px;
  margin: 3px 38px 0 0;
  color: #FFF;
  font-size: 12px;
  padding: 4px 0 3px 0;
  font-weight: normal;
  float: left;
  background: #0055a2;
  cursor: pointer;
}

#user-login-form .form-submit:hover {
  background: #003869;
}

#user-login-form .item-list ul {
  padding: 0 0 5px 0;
  margin: 0;
}

#user-login-form .item-list ul li {
  line-height: 20px;
  list-style: none;
  margin: 0;
  padding: 0;
}

#user-login-form .item-list ul li a {
  font-weight: normal;
  color: #7a7c88;
  text-decoration: underline;
  font-size: 11px;
}

#user-login-form .item-list ul li a:hover {
  text-decoration: none;
  color: #000;
}

#block-views-news-block_1 > .title h3 {
  font-size: 1.3rem;
  font-family: var(--bs-body-font-family);
  padding: 2rem 0 0.5rem;
  font-weight: 300;
  border-bottom: 1px solid #e2e2e2;
}

#block-views-news-block_1 .content {
  padding: 0;
}

#block-views-news-block_1 .more-link {
  padding-left: 10px;
  font-weight: bold;
  float: right;
}

#block-views-news-block_1 .feed-icon {
  float: right;
}

.block-quicktabs {
  background: #f1f8ff;
  border: 1px solid #dee8f3;
}

.block-quicktabs .title {
  padding-top: 7px;
}

.block-quicktabs .title > h3 {
  font-size: 1.3rem;
  font-weight: bold;
  font-family: var(--bs-body-font-family);
  font-weight: 300;
}

.block-quicktabs .quicktabs-hide {
  display: none;
}

.block-quicktabs .quicktabs_main {
  clear: both;
}

.block-quicktabs table.quicktab_block_table {
  width: 100%;
  height: 150px;
}

.block-quicktabs table.quicktab_block_table tr {
  vertical-align: top;
}

.block-quicktabs table.quicktab_block_table tr td.block_image {
  width: 128px;
}

@media (max-width: 950px) {
  .block-quicktabs table.quicktab_block_table tr td.block_image {
    display: none;
  }
}

@media (max-width: 950px) {
  .block-quicktabs table.quicktab_block_table ul {
    padding-left: 0.8rem;
  }
}

.block-quicktabs table.quicktab_block_table ul li {
  list-style: none;
  background: url(data:image/gif;base64,R0lGODlhBAAGAIABAD2C5////yH5BAEAAAEALAAAAAAEAAYAAAIIBBKGqGnnUAEAOw==) no-repeat 0 6px;
  padding: 0 0 0 12px;
  margin-bottom: 7px;
  line-height: 16px;
  text-transform: none;
}

.block-quicktabs table.quicktab_block_table ul li a {
  font-size: 12px;
  color: #444;
  text-decoration: none;
}

.block-quicktabs table.quicktab_block_table ul li a:hover {
  text-decoration: underline;
}

.block-quicktabs ul.quicktabs_tabs.quicktabs-style-zen {
  clear: both;
  margin: 0 0 10px 0;
  padding: 0 0 36px;
  font-size: 1em;
  list-style: none;
  height: 21px;
  background: none;
  border-bottom: 1px solid #dee8f3;
}

@media (max-width: 760px) {
  .block-quicktabs ul.quicktabs_tabs.quicktabs-style-zen {
    border-bottom: none;
  }
}

.block-quicktabs ul.quicktabs_tabs.quicktabs-style-zen li.active a, .block-quicktabs ul.quicktabs_tabs.quicktabs-style-zen li.active a:hover {
  text-decoration: none;
  border-width: 0;
}

.block-quicktabs ul.quicktabs_tabs.quicktabs-style-zen li {
  float: left;
  padding: 2px;
  margin: 5px 5px;
  background: #0055a2;
  display: inline;
  list-style-type: none;
  white-space: nowrap;
}

.block-quicktabs ul.quicktabs_tabs.quicktabs-style-zen li a {
  font: bold 12px/170% Verdana;
  font-size-adjust: none;
  display: block;
  margin: 0;
  padding: 4px 17px 0px 12px;
  border-width: 0;
  font-weight: bold;
  text-decoration: none;
}

.block-quicktabs ul.quicktabs_tabs.quicktabs-style-zen li a, .block-quicktabs ul.quicktabs_tabs.quicktabs-style-zen li a:hover {
  padding: 4px 17px 4px 12px;
  text-decoration: none;
  border-width: 0;
  background: #0055a2;
  color: #fff;
}

.block-quicktabs ul.quicktabs_tabs.quicktabs-style-zen li:hover a {
  border-width: 0;
  background: none;
}

.block-quicktabs ul.quicktabs_tabs.quicktabs-style-zen li.active {
  background: #003869;
}

.block-quicktabs ul.quicktabs_tabs.quicktabs-style-zen li.active a, .block-quicktabs ul.quicktabs_tabs.quicktabs-style-zen li.active a:hover {
  text-decoration: none;
  border-width: 0;
  background: #003869;
  color: #fff;
}

.block-quicktabs ul.quicktabs_tabs.quicktabs-style-zen li a:focus {
  outline: none;
}

#block-block-32, #block-block-33 {
  background: #F7F9FC;
  background-image: url(https://cryptopro.ru/sites/default/files/images/banner/banner_header_bg.svg);
  background-repeat: repeat-y;
  height: 314px;
  background-size: cover;
  border: 1px solid #dee8f3;
}

#block-block-32 {
  background-position: left top;
  border-right: none;
}

.leftBlock-block ul, .rightBlock-block ul, .contCol-block ul {
  padding-left: 0;
}

.leftBlock-block ul li, .rightBlock-block ul li, .contCol-block ul li {
  list-style: none;
  background: url(data:image/gif;base64,R0lGODlhBAAGAIABAD2C5////yH5BAEAAAEALAAAAAAEAAYAAAIIBBKGqGnnUAEAOw==) no-repeat 0 6px;
  padding: 0 0 0 12px;
}

.leftBlock-block ul li a, .rightBlock-block ul li a, .contCol-block ul li a {
  text-decoration: none;
}

.leftBlock-title, .rightBlock-title {
  height: 63px;
  border-bottom: 1px solid #dee8f3;
  display: flex;
  align-items: center;
}

.leftBlock-title .leftBlock-subtitle, .leftBlock-title .rightBlock-subtitle, .rightBlock-title .leftBlock-subtitle, .rightBlock-title .rightBlock-subtitle {
  font-size: 1.125rem;
  font-weight: 700;
}

.leftBlock-content {
  margin-top: 1rem;
  font-size: .875rem;
}

@media (max-width: 1360px) {
  #block-block-32 {
    border-right: 1px solid #dee8f3;
  }
}

#block-block-33 {
  background-position: right top;
}

.rightBlock-content {
  margin-top: 1rem;
  font-size: .875rem;
}

#block-block-34 {
  background: #F7F9FC;
  background-image: url(https://cryptopro.ru/sites/default/files/images/banner/banner_header_bg.svg);
  background-position: center top;
  height: 314px;
  background-size: cover;
  border: 1px solid #dee8f3;
}

.contBlock-header {
  display: flex !important;
  align-items: center !important;
  gap: 0.75rem !important;
  height: 63px;
  border-bottom: 1px solid #dee8f3;
}

.contBlock-header .btn {
  background-color: #f7faff;
  border: 1px solid #d5dae1;
  font-size: 14px;
  font-weight: 500;
  padding-bottom: 8px;
  padding-left: 16px;
  padding-right: 16px;
  padding-top: 8px;
}

.contBlock-header .btn:hover {
  background-color: #ebf3fe;
  border: 1px solid #d5dae1;
}

.contBlock-header .btn.active {
  background-color: #003869;
  color: white;
}

@media (max-width: 760px) {
  .contBlock-header {
    height: auto;
  }
}

@media (max-width: 760px) {
  #block-block-34 {
    height: auto;
  }
}

.smart-captcha {
  display: inline;
}
