.block {
  margin-bottom: $padding/2;
  color: #363638;
  position: relative;
  overflow: hidden;

  & > .title {
    & > h3 {
      color: #39464b;
      font-size: 20px;
      font-family: $cp-font-family-special-header;
      padding: 10px 0 0 21px;
      margin: 0;
    }
  }

  ul {
    //margin:0;
    //padding:0 0 0.25em 1em;
  }

  .menu-name-primary-links {
    ul.menu {
      padding-left: 20px;
    }
  }

  & > .content {
    color: #444;
    line-height: 22px;
    //font-size:13px;
    padding: 11px 19px 17px 21px;
    overflow: hidden;
    //a:hover{text-decoration:none;}
    & > ul li {
      padding: 0 0 0 12px;
      list-style: none;
      line-height: 16px;
      color: #444;
      margin-left: 0;
      text-transform: none;
      font-weight: normal;

      a {
        color: #444;
        text-transform: none;
        text-decoration: none;

        &:hover {
          text-decoration: underline;
        }
      }
    }
  }

  // Частные случаи: Меню пользователя
  &.block-user {
    ul.menu {
      padding: 0;
      & > li {
        background: url(data:image/gif;base64,R0lGODlhBAAGAIABAD2C5////yH5BAEAAAEALAAAAAAEAAYAAAIIBBKGqGnnUAEAOw==) no-repeat 0 9px;
        font-size: 13px;
        //border-bottom: 1px solid #d8e6f3;
        padding-top: 5px;
        padding-bottom: 5px;

        &:hover {
          background: #0055a2;

          a {
            text-decoration: none;
            color: #FFF;
          }
        }
      }
    }
  }

  // Частные случаи: Меню навигации
  &.block-menu_block {
    ul.menu {
      text-align: left;
      padding-left: 0;
      padding-bottom: 0;
      margin-bottom: 0;
      width: 100%;
      li {
        padding: 0 0 4px;
        overflow: inherit;
        position: relative;
        &.expanded {
          padding-bottom: 0;
          ul {
            padding-top: 1px;
            margin: 3px 0 0 10px;
          }
        }
        a {
          text-decoration: none;
          padding-left: 11px;
          display: block;
          color: #444;
          font-size: 13px;
          // border-bottom: 1px solid #d8e6f3;
          &:hover, &.active {
            color: #FFF;
            background: #0055a2;
          }
        }
      }
      &, ul {
        border-left: 1px dotted grey;
        padding-left: 5px
      }
      &>li:before, ul>li:before {
        border-bottom: 1px dotted grey;
        content: "";
        display: block;
        left: -5px;
        position: relative;
        top: 13px;
        width: 12px;
      }
      &>li:last-child:after, ul>li:last-child:after {
        background: #fff;
        bottom: 0;
        content: "";
        display: block;
        left: -10px;
        position: absolute;
        top: 14px;
        width: 8px;
      }
      &>li, ul>li {
        list-style: none;
      }
    }
  }
}

#right-col, #left-col {
  // Услуги УЦ и Услуги СЭП
  @at-root #block-block-4 .content ul.menu, #block-block-15 .content ul.menu{
    padding-left:0;
    padding-bottom:0;
    width:100%;
    .cp_linktext{
      position:absolute;
      top:50%;
      transform:translate(0, -50%);
    }
    li{
      padding:0 0 4px 0;
      list-style:none;
      margin:0;
      line-height:21px;
      overflow:hidden;
      a{
        font-size:12px;
        text-decoration:none;
        font-weight:normal;
        padding-left:11px;
        overflow:hidden;
        display:block;
        color:#0055a2;
        position:relative;
        &:hover{
          text-decoration:none;
          color:#FFF;
          background:#0055a2;
        }
      }
    }
  }

  @at-root #user-login-form{
    text-align:left;
    color:#FFF;
    font-size:12px;
    .form-required{color:#999999;}
    // Поля
    .form-item{
      margin-top:0;
      margin-bottom:10px;
      label{
        color:#7a7c88;
        font-size:12px;
        font-weight:normal;
        //padding:0 0 1px 0;
        line-height:normal;
      }
      input#edit-name, input#edit-pass{
        width:224px;
        //font-size:11px;
        //height:17px;
        color:#6f6f6f;
        background:#FFF;
        border:1px solid #c1c2c9;
        padding:2px 0 0 5px;
      }
    }
    // Кнопка войти
    .form-submit{
      border:none;
      width:64px;
      height:29px;
      margin:3px 38px 0 0;
      color:#FFF;
      font-size:12px;
      padding:4px 0 3px 0;
      font-weight:normal;
      float:left;
      background:#0055a2;
      cursor:pointer;
      &:hover{background:#003869;}
    }
    // Регистрация / Забыли пароль?
    .item-list ul{
      padding:0 0 5px 0;
      margin:0;
      li{
        line-height:20px;
        list-style:none;
        margin:0;
        padding:0;
        a{
          font-weight:normal;
          color:#7a7c88;
          text-decoration:underline;
          font-size:11px;
          &:hover{
            text-decoration:none;
            color:#000;
          }
        }
      }
    }
  }
}