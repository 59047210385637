// Блок списка новостей на главной
#block-views-news-block_1 {
  & > .title h3{ //Заголовок: "Новости КриптоПро"
    //color:#39464b;
    font-size: 1.3rem;
    font-family: var(--bs-body-font-family);
    padding: 2rem 0 0.5rem;
    //margin-bottom: 1rem;
    //color:#0055a2;
    font-weight: 300;
    border-bottom:1px solid #e2e2e2;
  }
  // Стандартное вложение .block > .content
  .content {
    padding: 0;
    // Блок списка новостей образуется длинным вложением .view ... > .views-row
    .views-row {
      // Конечная новость > .node
      & > .node {
      //width: 100%; //Исправление размера ноды
      //padding: 20px 0 10px; // Исправление наложения отступов .block > .content -> .node > .content
      }
    }
  }


  // Все новости. Внизу
  .more-link {
    padding-left: 10px;
    font-weight: bold;
    float: right;
  }
  // RSS
  .feed-icon {
    float: right;
  }
}