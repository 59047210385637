#footer{
  clear: both;
  font-weight:normal;
  font-size:14px;
  .privacy-policy{
    font-size:0.75em;
    line-height:20px;
    text-decoration:none;
  }
  a{
    color:#46535b;
    text-decoration:underline;
    &:hover{text-decoration:none;}
  }
  .foot{
    text-align:center;
    margin:0 auto;
    width:950px;
    padding:30px 0px 30px 0px;
    @media (max-width: 1060px) {
      & {
        width: 100%;
      }
    }
  }
  img.t_logo{
    width:20px;
    height:20px;
    position:relative;
    margin-bottom:-5px;
    filter:grayscale(1);
  }
}